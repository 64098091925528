/*==========================*\
  TABLE OF CONTENTS
  --------------------------
  Layout
  Typography
  General
  Components
  Playground <body>
  App Header <header>
  Content <main>
  Article <article>
  Editors 
  Results
  Overrides
      -CodeMirror
      -Spectrum
  webdesignplayground.io

\*==========================*/
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/*==========================*\
  LAYOUT
\*==========================*/
/* ------------ */
/* Mobile-first */
/* ------------ */
.playground {
    overflow: auto;
}

.header {
    float: none;
    height: 50px;
    width: 100%;
}

.site-logo {
    display: none;
}

.site-title {
    display: inline-block;
    height: 100%;
    width: 50%;
    font-size: 1rem;
}

.nav-toggle {
    display: inline-block;
    float: right;
    width: 20%;
    padding-top: .8rem;
    padding-right: 1rem;
    text-align: right;
}

.article {
    float: none;
    height: auto;
    width: 100%;
    min-width: 22rem;
    overflow-x: hidden;
    overflow-y: visible;
}

.editor-wrapper {
    float: none;
    height: 400px;
    min-height: 125px;
    width: 100%;
}

.results-wrapper {
    float: none;
    height: auto;
    width: 100%;
}

.CodeMirror {
    height: 355px;
    width: 100%;
}

.footer {
    float: none;
    height: 40px;
    width: 100%;
}

@supports (display: flex) {
    .article,
    .editor-wrapper,
    .results-wrapper {
        width: auto;
        height: auto;
    }
    .playground {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100vh;
        max-height: 100vh;
    }
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
    }
    /* ---------------------------------------------------------------*/
    /* The content flex items are indented from their flex containers */
    /* ---------------------------------------------------------------*/
    .content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
    }
    .article {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        min-height: 0;
    }
    .sandbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    .sandbox-editors-wrapper {
        display: flex;
        align-items: stretch;
        height: 33%;
        width: 100%;
    }
    .editor-wrapper {
        height: 100%;
        width: 100%;
    }
    .code-wrapper {
        height: calc(100% - 30px - 25px);
    }
    .editor-header {
        height: 30px;
    }
    .CodeMirror {
        width: auto;
        height: 100% !important;
    }
    .editor-footer {
        height: 25px;
    }
    .results-wrapper {
        width: 100%;
        height: 33%;
        display: flex;
    }
    .results-iframe {
        flex: 1;
    }
    .footer {
        flex: 0 0 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        clear: none;
    }
    .site-title {
        flex: 3;
        height: auto;
    }
    .nav-toggle {
        flex: 0 1 6rem;
        padding-top: 0;
    }
    .sandbox-editors-wrapper {
        flex-wrap: wrap;
    }
}

/* @supports (display: flex) */
/* --------------- */
/* Everything else */
/* --------------- */
@media (min-width: 501px) {
    .app {
        min-height: 100vh;
    }
    .site-logo {
        display: inline-block;
    }
    .article {
        float: left;
        height: calc(100vh - 50px - 40px);
        width: 34%;
    }
    .editor-wrapper--html {
        float: left;
        width: 33%;
    }
    .editor-wrapper--css {
        float: left;
        width: 33%;
    }
    .CodeMirror {
        width: 32vw;
    }
    .results-wrapper {
        clear: right;
        float: left;
        height: calc(100vh - 50px - 40px - 400px);
        width: 66%;
    }
    .footer {
        clear: all;
        position: fixed;
        bottom: 0;
    }
    @supports (display: flex) and (not (display: gridd)) {
        .article,
        .editor-wrapper,
        .results-wrapper {
            width: auto;
            height: auto;
        }
        .playground {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100vh;
            max-height: 100vh;
            min-height: 500px;
        }
        .header {
            flex: 0 0 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        /* ---------------------------------------------------------------*/
        /* The content flex items are indented from their flex containers */
        /* ---------------------------------------------------------------*/
        .content {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            max-height: calc(100vh - 50px - 40px);
        }
        .article {
            width: 100%;
            height: auto;
            min-height: 0;
        }
        .sandbox {
            width: 100%;
            display: flex;
            flex-direction: column;
            max-height: 100%;
        }
        .sandbox-editors-wrapper {
            display: flex;
            align-items: stretch;
            height: 33%;
            width: 100%;
        }
        .editor-wrapper {
            height: 100%;
            width: 50%;
        }
        .code-wrapper {
            height: calc(100% - 30px - 25px);
        }
        .editor-header {
            height: 30px;
        }
        .CodeMirror {
            width: auto;
            height: 100% !important;
        }
        .editor-footer {
            height: 25px;
        }
        .results-wrapper {
            width: 100%;
            height: 33%;
            display: flex;
        }
        .results-iframe {
            flex: 1;
        }
        .footer {
            flex: 0 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            clear: none;
            position: static;
            bottom: auto;
        }
    }
    /* @supports (display: flex) */
    @supports (display: gridd) {
        /* ---------------------------------------------------------------*/
        /* The .content grid is 2 rows and 96 columns.
        /* Having so many columns allows for fine-tuned width adjustments
        /* of the editors and the Lessons and Results windows.
        /* ---------------------------------------------------------------*/
        .article,
        .editor-wrapper,
        .results-wrapper {
            float: none;
            clear: none;
            width: auto;
            height: auto;
        }
        .playground {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 50px 1fr 40px;
            width: 100vw;
            height: 100vh;
        }
        .header {
            grid-column: 1;
            grid-row: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .footer {
            grid-column: 1;
            grid-row: 3;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .content {
            grid-column: 1;
            grid-row: 2;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        .article {
            grid-column: 1;
            grid-row: 1 / end;
            width: auto;
        }
        .sandbox {
            grid-column: 2 / end;
            grid-row: 1 / span 2;
            width: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        .sandbox-editors-wrapper {
            grid-column: 1 / end;
            grid-row: 1;
            display: grid;
            grid-template-columns: repeat(64, 1fr);
            grid-template-rows: 1fr;
        }
        .editor-wrapper {
            display: flex;
            flex-direction: column;
        }
        .editor-wrapper--html {
            grid-column: 1 / span 32;
            grid-row: 1;
        }
        .editor-wrapper--css {
            grid-column: 33 / end;
            grid-row: 1;
        }
        .editor-header {
            flex-grow: 0;
        }
        .CodeMirror {
            flex-grow: 1;
            width: auto;
        }
        .editor-footer {
            flex-grow: 0;
        }
        .results-wrapper {
            grid-column: 1 / end;
            grid-row: 2;
        }
    }
    /* @supports (display: grid) */
}

/* @supports (min-width: 45em) */
@media (min-width: 45rem) {
    @supports (display: flex) and (not (display: gridd)) {
        /* ---------------------------------------------------------------*/
        /* The content flex items are indented from their flex containers */
        /* ---------------------------------------------------------------*/
        .content {
            flex-wrap: nowrap;
        }
        .article {
            width: 33%;
        }
        .sandbox {
            width: 67%;
        }
        .sandbox-editors-wrapper {
            height: 50%;
        }
        .results-wrapper {
            width: auto;
            height: 50%;
        }
    }
    /* @supports (display: flex) */
}

/* @supports (min-width: 45em) */
/*==========================*\
  TYPOGRAPHY
\*==========================*/
html, body {
    color: #444;
    font-family: 'Source Sans Pro', Verdana, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.3;
    overflow: hidden;
}

a,
a:visited {
    color: inherit;
}

strong, b {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
}

em, i {
    font-family: 'Source Sans Pro Italic', Verdana, Helvetica, sans-serif;
}

code {
    background-color: #fcfcfc;
    color: #444;
    font-family: 'Source Code Pro', "Courier New", monospace;
    font-size: 1.125rem;
    border: 1px solid #ccc;
    border-radius: 5%;
    padding: 0 .25rem;
}

mark {
    background-color: yellow;
}

pre {
    font-family: 'Source Code Pro', Courier, monospace;
    white-space: nowrap;
    overflow-x: auto;
}

.playground-select {
    font-family: 'Source Sans Pro', Verdana, Helvetica, sans-serif;
    font-size: 3em;
}

ol > li {
    padding-top: .5em;
}

ul > li {
    padding-bottom: .5em;
}

/*==========================*\
  GENERAL RULES
\*==========================*/
.clearme:after {
    content: "";
    display: block;
    clear: both;
}

/*==========================*\
  COMPONENTS
\*==========================*/
/* ------- */
/* Buttons */
/* ------- */
.btn {
    margin-left: .5rem;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    color: #e8e8e8;
    letter-spacing: .5px;
}

.btn:hover {
    background-color: #ccc;
    color: #333;
}

.btn.disabled {
    background-color: #ddd;
}

.btn-link {
    margin-top: 1rem;
    padding: 1rem;
    text-decoration: none;
}

.btn:focus,
.btn:active:focus {
    outline: none;
}

.btn-icon {
    margin: 0 .5rem 0 0;
    padding-top: .4rem;
    vertical-align: baseline;
    width: 2rem;
}

.run-triangle {
    border-top: 7px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 12px solid #f8d030;
    display: inline-block;
    height: 0px;
    width: 0px;
    margin-left: .25rem;
}

/* ---- */
/* Tabs */
/* ---- */
.tool-tabs {
    list-style-type: none;
    border-bottom: 1px solid #ccc;
    padding-left: 1rem;
}

.tool-tab {
    display: inline-block;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none;
    border-left: 1px solid #ccc;
    border-radius: 1rem 1rem 0 0;
    margin-right: -3px;
    padding: .5rem 1rem;
    background-color: white;
    cursor: pointer;
}

.tool-tab.active {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
    cursor: default;
}

.tool-box {
    display: none;
    opacity: 1;
    transition: opacity 1.5s ease;
}

.tool-box.active {
    display: block;
    animation: fade-in 1s;
}

.tool-related-tools {
    display: none;
    margin-top: 1rem;
    opacity: 1;
}

.tool-related-tools.active {
    display: block;
    animation: fade-in 1s;
}

.related-tools-list {
    list-style-type: none;
    padding-left: 0;
}

.related-tools-list li a {
    color: #d93b30;
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
    font-size: 1.1rem;
    text-decoration: none;
}

.related-tools-list li a:hover {
    color: #f8d030;
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-skip-ink: auto;
}

.user-sandbox-thumbnail-wrapper {
    position: relative;
}

.user-sandbox-thumbnail-wrapper > a:last-child {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 300px;
    height: 256px;
    z-index: 5;
}

.user-sandbox-thumbnail,
.related-tools-iframe {
    float: left;
    width: 1200px;
    height: 1024px;
    border: none;
    margin-bottom: -750px;
    transform: scale(0.25);
    transform-origin: 0 0;
}

.user-sandbox-thumbnail {
    border: 2rem outset lightgray;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* ------------ */
/* Modal Dialog */
/* ------------ */
.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
}

.modal-dialog {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 1rem;
    height: auto;
    width: auto;
    border-radius: .25rem;
    box-shadow: .25rem .25rem .25rem #333;
    background-color: #ccc;
}

.modal-content {
    padding: .5rem;
    color: #333;
}

.modal-title {
    font-size: 1.5rem;
    color: #a30f0f;
    text-align: center;
}

.modal-message {
    padding: 1rem;
    font-size: 1.25rem;
    color: #333;
}

.modal-buttons {
    text-align: center;
}

.modal-accept {
    border-width: 2px;
    margin-right: 2rem;
    padding: .5rem;
}

.modal-cancel {
    padding: .5rem;
}

/* ------*/
/* Table */
/* ------*/
.table-wrapper {
    display: table;
    border-spacing: 2rem .5rem;
}

.table-header {
    display: table-header-group;
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

/*==========================*\
  PLAYGROUND <body>
\*==========================*/
.playground {
    margin: 0;
    padding: 0;
}

/*==========================*\
  APP HEADER <header>
\*==========================*/
.header {
    z-index: 15;
    background-color: #f8d030;
}

.site-title {
    margin: 0;
    padding: .4rem 0 0 .75rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
}

.site-logo {
    padding-top: .25rem;
}

.site-title--o {
    display: inline-block;
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-skip-ink: auto;
}

.nav-sandbox-save {
    display: inline-block;
    text-align: right;
    vertical-align: top;
}

.nav-sandbox-save-wrapper {
    display: none;
}

@media (min-width: 30rem) {
    .site-title a {
        font-size: 1.25rem;
    }
    .site-title--o {
        font-size: .6em;
        transform: translateY(-0.35rem);
    }
}

@media (min-width: 45rem) {
    .site-title a {
        font-size: 1.25rem;
    }
    .site-title--o {
        font-size: .6em;
        transform: translateY(-0.45rem);
    }
    @supports (display: flex) {
        .site-title {
            flex: 3 1;
        }
        .nav-sandbox-save {
            flex: 3 1;
            margin-right: 1rem;
        }
        .nav-toggle {
            flex: 0 1 6rem;
            padding-top: 0;
        }
    }
}

@media (min-width: 55rem) {
    .site-title a {
        font-size: 1.5rem;
    }
    .site-title--o {
        font-size: .6em;
        transform: translateY(-0.45rem);
    }
}

@media (min-width: 65rem) {
    .site-title a {
        font-size: 1.75rem;
    }
    .site-title--o {
        font-size: .6em;
        transform: translateY(-0.45rem);
    }
    @supports (display: flex) {
        .nav-sandbox-save {
            flex: 1 1 20rem;
            margin-right: 2rem;
        }
    }
}

/*
.article,
.sandbox {
    display: none;
}
*/
.editor-wrapper--js {
    display: none;
}

/*==========================*\
  CONTENT <main>
\*==========================*/
/*==========================*\
  ARTICLE NAVIGATION <nav>
\*==========================*/
/* ------------------------- */
/* Article Header Navigation */
/* ------------------------- */
.article-nav {
    display: none;
    top: 0;
    height: 7rem;
    margin: .5rem;
    border-bottom: 1px solid #ddd;
}

.article-nav--container {
    width: 100%;
}

.ui-selectmenu-button.ui-button {
    width: 98% !important;
}

.ui-selectmenu-text {
    font-size: .9rem;
}

.article-nav--item {
    width: 100%;
    padding-top: 0;
    padding-right: .5rem;
}

@supports (display: flex) {
    .article-nav {
        display: flex;
        flex-direction: column;
    }
    .article-nav--container {
        flex: 1;
    }
    .article-nav--item {
        flex: 1;
        padding: 0;
        display: flex;
        align-items: flex-start;
    }
    .article-nav--item-left {
        flex: 1;
        text-align: left;
    }
    .article-nav--item-right {
        flex: 1;
        text-align: right;
    }
}

.article-nav-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin: 0 .5rem 0 0;
    padding: 0 .25rem 0 .25rem;
    opacity: 1;
    cursor: pointer;
}

.article-nav-button:nth-child(2) {
    text-align: end;
}

.article-nav-button.disabled {
    opacity: .4;
    cursor: not-allowed;
}

.article-nav-button img {
    display: inline-block;
    height: 1rem;
    width: 1.25rem;
    margin: .5rem .25rem;
    vertical-align: middle;
}

/*==========================*\
  ARTICLE <article>
\*==========================*/
.article {
    padding: 0 1rem 40px 1rem;
    font-size: 1rem;
    overflow-y: auto;
}

.article-surtitle {
    padding-left: .5rem;
    margin: 0;
    font-size: 1.25em;
}

.article-title {
    padding: 0 .5rem;
    margin: .5rem 0 0 0;
    font-size: 1.75em;
}

.article-subtitle {
    padding: 0 .5rem 0 .5rem;
    margin: 0 0 1rem 0;
    font-size: 1.5em;
}

.article-text {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1.25rem;
}

.article-heading {
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.article-code {
    margin-top: .5rem;
    background-color: #f5f5f5;
    white-space: pre-line;
    overflow-x: auto;
    overflow-y: hidden;
}

.article-code--html {
    border: 1px solid #1dafec;
    box-shadow: 1px 1px 1px #333;
    margin-top: .5rem;
    font-family: 'Source Code Pro', Courier, monospace;
    background-color: #f5f5f5;
    white-space: pre;
}

.article-code--html::before {
    content: 'HTML';
    display: block;
    margin-bottom: .5rem;
    padding: .25rem .5rem;
    border-bottom: 1px solid #1dafec;
    color: #1dafec;
    font-weight: bold;
    background-color: #f4fbfe;
}

.article-code--html-footer {
    margin-top: .5rem;
    padding: .5rem;
    border: 1px solid #1dafec;
    background-color: #f4fbfe;
    color: #1dafec;
    font-weight: bold;
    text-align: right;
}

.article-code--css {
    border: 1px solid #ea2c93;
    box-shadow: 1px 1px 1px #333;
    font-family: 'Source Code Pro', Courier, monospace;
    background-color: #f5f5f5;
    white-space: pre;
}

.article-code--css::before {
    content: 'CSS';
    display: block;
    margin-bottom: .5rem;
    padding: .25rem .5rem;
    border-bottom: 1px solid #ea2c93;
    color: #ea2c93;
    font-weight: bold;
    background-color: #fef7fa;
}

.article-code--css-footer {
    margin-top: .5rem;
    padding: .5rem;
    border-top: 1px solid #ea2c93;
    background-color: #fef7fa;
    color: #ea2c93;
    font-weight: bold;
    text-align: right;
}

.article-code--js {
    border: 1px solid #2b4521;
    box-shadow: 1px 1px 1px #333;
    font-family: 'Source Code Pro', Courier, monospace;
    background-color: #f5f5f5;
    white-space: pre;
}

.article-code--js::before {
    content: 'JavaScript';
    display: block;
    margin-bottom: .5rem;
    padding: .25rem .5rem;
    border-bottom: 1px solid #2b4521;
    color: #2b4521;
    font-weight: bold;
    background-color: #d8e9d2;
}

.article-code--js-footer {
    margin-top: .5rem;
    padding: .5rem;
    border-top: 1px solid #2b4521;
    background-color: #d8e9d2;
    color: #2b4521;
    font-weight: bold;
    text-align: right;
}

.article-code-wrapper {
    display: block;
    font-size: .9rem;
    padding-left: .5rem;
    padding-right: .5rem;
    overflow-x: auto;
    overflow-y: hidden;
}

.article-code--tag {
    color: #1dafec;
}

.article-code--property {
    color: #ea2c93;
}

.article-code--copy-button {
    margin-right: .5rem;
    padding: .5rem .75rem;
    font-size: 1rem;
}

.article-sidebar {
    margin: 1rem .5rem 1rem .5rem;
    padding: 0.5em;
    background-color: #f5f5f5;
    border-radius: .75rem;
}

.article-sidebar--note {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
    color: #1c4587;
    /* dark blue */
}

.article-sidebar--tip {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
    color: #69a64e;
}

.article-sidebar--beware {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
    color: red;
}

.article-show-answer {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #999;
    width: 100%;
}

.article-show-answer-button-wrapper {
    margin: 1rem;
    text-align: center;
}

.article-show-answer-button {
    padding: .5rem;
}

.article-answer-text {
    display: block;
    margin-top: 1rem;
    opacity: 0;
    transition: opacity .5s ease-out;
}

.article-answer-text.active {
    opacity: 1;
}

.article-show-it--button {
    margin-left: 0;
    padding: .5rem;
}

.article-show-it--progress,
.article-show-it--progress1,
.article-show-it--progress2 {
    margin-top: 1rem;
}

.article-control-label {
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
}

.article-control-label:not:first-child {
    margin-top: 1rem;
}

.article-text-box {
    height: 2rem;
    width: 2.5rem;
    margin-left: .5rem;
}

.article-select,
.tool-select {
    margin-left: .5em;
    font-size: 1rem;
}

.article-toc {
    list-style-type: none;
}

/* ----- */
/* Tools */
/* ----- */
.tool-box {
    padding: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tool-header {
    color: #333;
    margin-top: 0;
    margin-left: 0;
    width: auto;
}

.tool-heading {
    font-size: 1.5rem;
}

.tool-wrapper {
    margin-top: 1rem;
}

.tool-label {
    margin-top: 1rem;
    margin-bottom: .5rem;
    padding-right: 1rem;
    font-family: 'Source Sans Pro Bold', Verdana, Helvetica, sans-serif;
}

.tool-label:not(first-child) {
    margin-top: 1.5rem;
}

.tool-controls-wrapper {
    margin-left: 2rem;
}

.tool-radio {
    display: block;
    margin-top: .25rem;
}

.tool-radio-inline {
    display: inline-block;
    margin: .25rem .5rem 0;
}

.tool-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
}

.tool-text-box {
    width: 3em;
}

.tool-control-wrapper {
    margin-bottom: 1rem;
}

.tool-control-addon {
    display: inline-block;
    border: 1px solid #aaa;
    padding: .25rem;
    font-size: .9rem;
    background-color: #eee;
    vertical-align: bottom;
}

@supports (display: flexy) {
    .tool-wrapper {
        display: flex;
        align-items: center;
    }
    .tool-label {
        flex: 1;
        margin: 0;
        padding-left: 0;
        font-weight: bold;
    }
    .tool-control {
        flex: 6;
    }
}

/*==========================*\
  SANDBOX
\*==========================*/
.editor-wrapper {
    border: 1px solid #ccc;
    background-color: #f5f5f5;
}

.editor-header {
    height: 30px;
    padding: 0 0 .1rem 1rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: .05rem;
}

.editor-footer {
    height: 25px;
    padding: 0 0 .1rem 0rem;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: .05rem;
}

.editor-resize-button {
    border: none;
    padding: .4rem 0 0 .25rem;
    font-size: 1.5rem;
    line-height: .75;
    color: #1dafec;
    background-color: #f4fbfe;
}

.editor-title {
    display: inline-block;
    width: 50%;
    margin: .5rem 0 0 0;
    text-align: left;
}

.editor-toggle {
    display: inline-block;
    width: 45%;
    text-align: left;
    vertical-align: top;
}

.editor-menu {
    position: absolute;
    top: 30px;
    z-index: -1;
    padding: 0;
    opacity: 0;
    transition: opacity .5s ease-out;
}

.editor-menu.active {
    opacity: 1;
    z-index: 10;
}

.editor-menu-list {
    padding: 0 1rem;
    list-style-type: none;
    cursor: pointer;
}

.editor-menu-item {
    width: 100%;
    padding: .33rem .5rem 0;
}

.editor-menu-item.disabled {
    cursor: not-allowed;
}

.editor-menu-copied {
    display: inline-block;
    margin: .75rem 0 0 .5rem;
    font-size: .9rem;
    opacity: 0;
}

/* ------------------ */
/* Editor Menu Toggle */
/* ------------------ */
.editor-toggle {
    cursor: pointer;
}

.editor-toggle-hamburger {
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    z-index: 10;
}

/*==========================*\
  SANDBOX HTML EDITOR
\*==========================*/
.editor-header--html,
.editor-footer--html,
.editor-wrapper--html {
    color: #1dafec;
    background-color: #f4fbfe;
}

.editor-wrapper--html .CodeMirror-gutter-background.CodeMirror-activeline-gutter {
    background-color: #f4fbfe;
}

.editor-wrapper--html .CodeMirror-gutters {
    background-color: #f4fbfe !important;
}

.editor-menu--html {
    background-color: #f4fbfe;
    border-right: 1px solid #1dafec;
    border-bottom: 1px solid #1dafec;
    box-shadow: 1px 1px 1px #1dafec;
}

.editor-menu--html .editor-menu-item:hover {
    background-color: #1dafec;
    color: #f4fbfe;
}

.editor-menu--html .editor-menu-item.disabled:hover {
    background-color: #f4fbfe;
    color: #1dafec;
}

.editor-toggle-hamburger-line--html {
    display: block;
    height: 3px;
    margin-top: 3px;
    background-color: #1dafec;
    opacity: 1;
    transition: background-color .5s ease-in, opacity .5s ease-in, transform .5s ease-out;
}

.editor-toggle-hamburger-line--html:nth-child(1).active {
    transform: rotate(45deg) translate(4px, 3px);
}

.editor-toggle-hamburger-line--html:nth-child(2).active {
    opacity: 0;
}

.editor-toggle-hamburger-line--html:nth-child(3).active {
    transform: rotate(-45deg) translate(5px, -5px);
}

/*==========================*\
  SANDBOX CSS EDITOR
\*==========================*/
.editor-header--css,
.editor-footer--css,
.editor-wrapper--css {
    color: #ea2c93;
    background-color: #fef7fa;
}

.editor-wrapper--css .CodeMirror-gutter-background.CodeMirror-activeline-gutter {
    background-color: #fef7fa;
}

.editor-wrapper--css .CodeMirror-gutters {
    background-color: #fef7fa !important;
}

.editor-menu--css {
    background-color: #fef7fa;
    border-right: 1px solid #ea2c93;
    border-bottom: 1px solid #ea2c93;
    box-shadow: 1px 1px 3px #ea2c93;
}

.editor-menu--css .editor-menu-item:hover {
    background-color: #ea2c93;
    color: #fef7fa;
}

.editor-menu--css .editor-menu-item.disabled:hover {
    background-color: #fef7fa;
    color: #ea2c93;
}

.editor-toggle-hamburger-line--css {
    display: block;
    height: 3px;
    margin-top: 3px;
    background-color: #ea2c93;
    opacity: 1;
    transition: background-color .5s ease-in, opacity .5s ease-in, transform .5s ease-out;
}

.editor-toggle-hamburger-line--css:nth-child(1).active {
    transform: rotate(45deg) translate(4px, 3px);
}

.editor-toggle-hamburger-line--css:nth-child(2).active {
    opacity: 0;
}

.editor-toggle-hamburger-line--css:nth-child(3).active {
    transform: rotate(-45deg) translate(5px, -5px);
}

/*==========================*\
  SANDBOX JS EDITOR
\*==========================*/
.editor-header--js,
.editor-footer--js,
.editor-wrapper--js {
    color: #2b4521;
    background-color: #d8e9d2;
}

.editor-wrapper--js .CodeMirror-gutter-background.CodeMirror-activeline-gutter {
    background-color: #d8e9d2;
}

.editor-wrapper--js .CodeMirror-gutters {
    background-color: #d8e9d2 !important;
}

.editor-menu--js {
    background-color: #d8e9d2;
    border-right: 1px solid #2b4521;
    border-bottom: 1px solid #2b4521;
    box-shadow: 1px 1px 3px #2b4521;
}

.editor-menu--js .editor-menu-item:hover {
    background-color: #2b4521;
    color: #d8e9d2;
}

.editor-menu--js .editor-menu-item.disabled:hover {
    background-color: #d8e9d2;
    color: #2b4521;
}

.editor-toggle-hamburger-line--js {
    display: block;
    height: 3px;
    margin-top: 3px;
    background-color: #2b4521;
    opacity: 1;
    transition: background-color .5s ease-in, opacity .5s ease-in, transform .5s ease-out;
}

.editor-toggle-hamburger-line--js:nth-child(1).active {
    transform: rotate(45deg) translate(4px, 3px);
}

.editor-toggle-hamburger-line--js:nth-child(2).active {
    opacity: 0;
}

.editor-toggle-hamburger-line--js:nth-child(3).active {
    transform: rotate(-45deg) translate(5px, -5px);
}

/*==========================*\
  SANDBOX RESULTS
\*==========================*/
.results-wrapper {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.results {
    width: 100%;
    height: 100%;
    border-width: 0;
    overflow: auto;
}

/*==========================*\
  APP FOOTER
\*==========================*/
.footer {
    background-color: #f8d030;
}

/*==========================*\
  GENERAL FORM STYLES
\*==========================*/
.form-wrapper {
    margin-bottom: 1rem;
    max-width: 400px;
}

.control-wrapper {
    flex: 1;
    margin-top: 1rem;
}

.form-control {
    height: 2rem;
    width: 100%;
}

textarea.form-control {
    height: auto;
    width: 100%;
}

.form-control[name='password'] {
    margin-bottom: .25rem;
}

label {
    display: block;
    margin-bottom: .2rem;
}

.label-horizontal {
    display: inline;
    margin-left: .25rem;
}

input[type='checkbox'] {
    margin-left: 0;
    height: auto;
    width: auto;
}

.form-wrapper button {
    margin: 1rem 1rem 1rem 0;
}

.error-message {
    display: none;
    color: red;
}

.form-message {
    display: none;
}

.error {
    border-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.8);
}

input:valid {
    border-color: lightgrey;
    box-shadow: none;
}

/*==========================*\
  THIRD-PARTY OVERRIDES
\*==========================*/
/* ---------- */
/* Codemirror */
/* ---------- */
.CodeMirror {
    background-color: #ffffff;
    color: #222;
    line-height: 1.4375;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.cm-comment {
    color: #75787b;
}

.cm-keyword, .cm-property {
    color: #1d75b3 !important;
}

.cm-atom, .cm-number {
    color: #75438a;
}

.cm-node, .cm-tag {
    color: #1dafec !important;
}

.cm-string {
    color: #b35e14;
}

.cm-variable, .cm-qualifier {
    color: #047d65;
}

.cm-s-base16-light span.cm-string {
    color: #000 !important;
}

.cm-error {
    background-color: #f5b7b8 !important;
}

/* Editor styling */
.CodeMirror-gutters {
    border: none;
    border-right: 10px solid transparent;
    background-color: transparent;
}

.CodeMirror-linenumber {
    padding: 0;
    color: #e0e2e5;
}

.CodeMirror-guttermarker {
    color: #1d75b3;
}

.CodeMirror-guttermarker-subtle {
    color: #e0e2e5;
}

.CodeMirror-cursor {
    width: auto;
    border: 0;
    background: rgba(155, 157, 162, 0.37);
    z-index: 1;
}

/* --------- */
/* jQuery UI */
/* --------- */
.ui-tooltip {
    box-shadow: 3px 3px 6px #999 !important;
    font-size: .9rem !important;
}

.ui-icon-info {
    margin-top: -1rem !important;
    margin-left: .25rem !important;
}

/* -------- */
/* Spectrum */
/* -------- */
.sp-container {
    z-index: 1;
}

.sp-input {
    font-size: 11.5px !important;
    padding: 4px 0 4px 1px;
}

/* ---------------------- */
/* webcodingplayground.io */
/* ---------------------- */
.example-select-wrapper {
    margin: 1rem .5rem;
}

.example-select-label {
    display: inline-block;
    min-width: 8rem;
    font-size: 1rem;
    font-weight: bold;
}

.example-select {
    width: 100%;
    font-size: .9rem;
}

/* ---------------------- */
/* webdesignplayground.io */
/* ---------------------- */
/*==========================*\
  FONTS
\*==========================*/
@font-face {
    font-family: 'MuseoSlab-500';
    src: url("/fonts/33407A_0_0.woff2") format("woff2"), url("/fonts/33407A_0_0.woff") format("woff");
}

@font-face {
    font-family: 'Source Code Pro';
    src: url("/fonts/sourcecodepro-regular-webfont.woff2") format("woff2"), url("/fonts/sourcecodepro-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url("/fonts/sourcesanspro-bold-webfont.woff2") format("woff2"), url("/fonts/sourcesanspro-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Bold Italic';
    src: url("/fonts/sourcesanspro-bolditalic-webfont.woff2") format("woff2"), url("/fonts/sourcesanspro-bolditalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Italic';
    src: url("/fonts/sourcesanspro-italic-webfont.woff2") format("woff2"), url("/fonts/sourcesanspro-italic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url("/fonts/sourcesanspro-regular-webfont.woff2") format("woff2"), url("/fonts/sourcesanspro-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/*==========================*\
  GENERAL STYLES
\*==========================*/
body {
    color: #4c3e01;
}

/*==========================*\
  LAYOUT OVERRIDES
\*==========================*/
@media (min-width: 45rem) {
    @supports (display: flex) {
        .article {
            width: 33%;
        }
        .editor-wrapper {
            width: 50%;
            min-width: 40px;
        }
        .sandbox-editors-wrapper {
            flex-wrap: nowrap;
        }
    }
}

/*==========================*\
  COMPONENTS
\*==========================*/
/* ------- */
/* Buttons */
/* ------- */
.btn {
    background-color: #181818;
    border: 1px solid #181818;
    color: #f8f8f8;
}

.btn:hover {
    background-color: #181818;
    color: #ffde5c;
}

.btn.disabled {
    background-color: #666;
    color: #ccc;
}

.btn-group button {
    height: 2rem;
    width: 2rem;
    margin: 0 -.2rem 0 0;
}

.btn-group button.active {
    box-shadow: inset 0 3px 5px #999;
    background-color: #ffe98f;
    background-image: -webkit-linear-gradient(top, #ffde5c 0%, #ffe98f 100%);
    background-image: linear-gradient(to bottom, #ffde5c 0%, #ffe98f 100%);
}

.cancel-button {
    background-color: #fff5cc;
    background-image: -webkit-linear-gradient(top, #ffe98f 0%, #fff5cc 100%);
    background-image: linear-gradient(to bottom, #fff5cc 0%, #ffe98f 100%);
}

/* -------- */
/* Tooltips */
/* -------- */
.tooltip {
    position: relative;
}

.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    top: -150%;
    left: 0;
    z-index: 11;
    height: 1.5em;
    padding: 0 .5em;
    white-space: nowrap;
    font-family: "Source Sans Pro", sans-serif;
    font-size: .9rem;
    line-height: 1.5em;
    color: black;
    background-color: #ffde5c;
    background-image: -webkit-linear-gradient(top, #ffe98f 0%, #ffde5c 100%);
    background-image: linear-gradient(to bottom, #ffe98f 0%, #ffde5c 100%);
    visibility: hidden;
}

.tooltip:hover::before {
    visibility: visible;
}

.tooltip::before {
    border: 1px solid #ae8c04;
    box-shadow: 1px 1px 1px #4c3e01;
    color: #4c3e01;
}

/* ---- */
/* Tabs */
/* ---- */
.tool-tabs {
    border-color: #f8d030;
}

.tool-tab {
    border-color: #ae8c04;
    color: #ae8c04;
}

.tool-tab.active {
    border-color: #4c3e01;
    background-color: #f8d030;
    color: #4c3e01;
}

/*==========================*\
  APP HEADER <header>
\*==========================*/
.header {
    border-bottom: 2px solid #ae8c04;
    background-color: #f8d030;
}

.site-logo {
    margin-left: .75rem;
}

.site-title a {
    color: #4c3e01;
    font-family: "MuseoSlab-500";
    text-decoration: none;
}

.header-buttons--sandbox {
    margin-right: 1rem;
}

.header-button {
    height: calc(50px * .7);
    margin-left: .2rem;
    padding: 0 .25rem;
    font-size: .75rem;
}

@media (min-width: 501px) {
    .header-button {
        margin-left: .25rem;
        padding: 0 .5rem;
        font-size: .9rem;
    }
}

/*          -----------------------          */
/* ======== Header Sandbox controls ======== */
/*          -----------------------          */
.nav-sandbox-title-label {
    display: inline-block;
    margin-right: .5rem;
    font-size: 1.1rem;
    font-weight: bold;
    color: #4c3e01;
}

input.nav-sandbox-title-textbox[type="text"] {
    display: none;
    width: 1rem;
    font-family: 'Source Sans Pro', Verdana, Helvetica, sans-serif;
    background-color: #ffde5c;
}

.nav-sandbox-title-display {
    display: inline-block;
    width: auto;
    font-size: 1.1rem;
}

.nav-sandbox-title-edit {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin-left: .5rem;
    color: #ae8c04;
    cursor: pointer;
}

.nav-button {
    height: calc(50px - 1rem);
}

/*          ------------------          */
/* ======== Off-canvas results ======== */
/*          ------------------          */
.ajax-result {
    position: absolute;
    top: 0;
    left: calc(100vw / 2 - 50px);
    z-index: 10;
    width: 8rem;
    height: auto;
    border-radius: 0 0 7px 7px;
    font-size: .9rem;
    background-color: white;
    text-align: center;
    overflow-y: auto;
    opacity: 0;
    transform: translate3d(100vw, -100vh, 0);
}

.ajax-result.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .5s ease-out, transform .5s ease-out;
}

/*          ----------------          */
/* ======== Off-canvas menus ======== */
/*          ----------------          */
.nav-menu {
    position: absolute;
    top: 50px;
    left: 0;
    top: 50px;
    z-index: 10;
    width: 100vw;
    height: calc(100vh - 50px);
    font-size: .9rem;
    background-color: #f8f8f8;
    color: #181818;
    text-align: center;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s ease-out;
}

.nav-menu.active {
    opacity: 1;
    visibility: visible;
    transition: opacity .5s ease-out;
}

.nav-menu-slice {
    display: inline-block;
    vertical-align: top;
    margin-right: 5rem;
    text-align: left;
}

.nav-menu-slice--chapters {
    width: 23rem;
}

.nav-menu-slice--other {
    width: 12rem;
}

.nav-menu-header {
    width: 100%;
    padding-top: 1.5rem;
    color: #181818;
}

.nav-menu-heading {
    margin-bottom: 0;
}

.nav-menu-list {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    position: relative;
}

.nav-menu-list--sandbox li {
    transition-delay: .7s;
}

.nav-menu-item {
    display: block;
    width: 100%;
    padding: .5em;
    cursor: pointer;
}

.nav-menu-item:focus,
.nav-submenu-item:focus {
    outline: 2px dotted #4c3e01;
}

.nav-menu-item.disabled:hover {
    background: #ae8c04;
    cursor: not-allowed;
}

.nav-menu-item--chapter::after {
    content: '\00a0\00a0\02304';
}

.nav-submenu {
    list-style-type: none;
    display: none;
    z-index: 15;
    padding-left: 1.5rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 2.5s ease-in;
}

.nav-submenu.active {
    opacity: 1;
    display: block;
}

.nav-submenu-item code {
    color: #f5f5f5;
}

.nav-menu-item:hover,
.nav-submenu-item:hover {
    background: rgba(0, 0, 0, 0.15);
    color: #4c3e01;
}

/*          ----------------------          */
/* ======== Off-canvas menu toggle ======== */
/*          ----------------------          */
.nav-toggle {
    cursor: pointer;
}

.nav-toggle-hamburger {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 10;
}

.nav-toggle-hamburger:focus {
    outline: none;
}

.nav-toggle-hamburger-line {
    display: block;
    height: 4px;
    margin-top: 4px;
    background-color: #4c3e01;
    opacity: 1;
    transition: background-color .5s ease-in, opacity .5s ease-in, transform .5s ease-out;
}

.nav-toggle-hamburger-line:nth-child(1).active {
    transform: rotate(45deg) translate(7px, 6px);
}

.nav-toggle-hamburger-line:nth-child(2).active {
    opacity: 0;
}

.nav-toggle-hamburger-line:nth-child(3).active {
    transform: rotate(-45deg) translate(5px, -5px);
}

.nav-toggle-text {
    display: none;
    vertical-align: bottom;
    padding-right: .4rem;
    color: #4c3e01;
    font-size: .9rem;
    font-weight: bold;
    letter-spacing: .05rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .5s ease-in;
}

/*==========================*\
  ARTICLE <article>
\*==========================*/
.article-surtitle {
    color: #ae8c04;
}

.article-title,
.article-subtitle {
    color: #4c3e01;
}

.article-sidebar {
    background-color: #fff5cc;
}

.article-show-it--button {
    margin-left: 0;
}

.article-control-label {
    color: #ae8c04;
}

/*          -----          */
/* ======== Tools ======== */
/*          -----          */
.related-tools-list li a {
    color: #4c3e01;
}

.related-tools-list li a:hover {
    color: #f8d030;
}

/*==========================*\
  SANDBOX
\*==========================*/
.editor-wrapper {
    border-color: #ccc;
}

/*==========================*\
  SANDBOX RESULTS
\*==========================*/
.results-wrapper {
    border-left: 1px solid #ccc;
}

.footer {
    background-color: #f8d030;
    border-top: 2px solid #ae8c04;
}

/*==========================*\
  FOOTER
\*==========================*/
.footer-buttons--user {
    flex: 1;
    margin-left: 1rem;
}

.footer-buttons--sandbox {
    margin-right: 1rem;
}

.footer-button {
    height: calc(40px * .8);
    margin-left: .2rem;
    padding: 0 .25rem;
    font-size: .75rem;
}

@media (min-width: 501px) {
    .footer-button {
        margin-left: .25rem;
        padding: 0 .5rem;
        font-size: .9rem;
    }
}

@media (min-width: 50rem) {
    .nav-toggle-text {
        display: inline-block;
    }
}
